<!--
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-21 10:10:23
 * @LastEditors: YGQ
 * @LastEditTime: 2024-07-30 18:18:29
 * @FilePath: /oneid/src/views/home/dashboard.vue
 * @Description: 工作台
-->
<template>
  <div>
    <img class="bg" src="@/assets/dashboard/bg.webp" />
    <Header />
    <div class="font20 N2 bold" style="margin: 8px 0 24px">
      请选择需要进入的应用
    </div>
    <div class="content">
      <div class="flex-wrap">
        <AppCard v-for="app in index" :key="app.title" :app="app" />
      </div>
    </div>
    <DialogPassword
      :visible="dialogName == 'password'"
      :closable="false"
      @hide="dialogName = null"
    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import AppCard from "./components/AppCard";
import DialogPassword from "./components/DialogPassword";

export default {
  components: {
    Header,
    AppCard,
    DialogPassword,
  },
  data() {
    return {
      index: [
        {
          id: "pms",
          title: "云PMS系统",
          note: "酒店日常运营管理工具，支持全渠道订单直连与运营数据采集，助力酒店运营管理数字化降本增效。",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms/#/oneid",
              BY: "https://pms.geemro.cn/pms/#/oneid",
            }, // MR默认线路 BY备用
            trial: { MR: "https://trial.geemro.com/pms/#/oneid" },
            develop: { MR: "https://dev.geemro.com/pms/#/oneid" },
            local: { MR: "https://dev.geemro.com/pms/#/oneid" },
          },
          color: "#5ea9fb",
          bgColor: "linear-gradient(241deg, #5798F7 0%, #3385F6 100%)",
        },
        {
          id: "onedata",
          title: "报表中心",
          note: "所有应用查询与导出数据报表的统一入口，酒店运营管理数据可视化平台。",
          url: {
            release: {
              MR: "https://onedata.geemro.com/onedata/#/oneid",
              BY: "https://onedata.geemro.com/onedata/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/onedata/#/oneid" },
            develop: { MR: "https://dev.geemro.com/onedata/#/oneid" },
            local: { MR: "https://dev.geemro.com/onedata/#/oneid" },
          },
          color: "#41BA6E",
          bgColor: "linear-gradient(241deg, #37BE70 0%, #3AB566 100%)",
        },
        {
          id: "pms-manage",
          title: "PMS管理后台",
          note: "日常运营后台管理系统，通过数字化应用赋能，助力酒店管理自动化，提高运营人员工作效率。",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms-manage/#/oneid",
              BY: "https://pms.geemro.cn/pms-manage/#/oneid",
            }, // release = 生产环境
            trial: { MR: "https://trial.geemro.com/pms-manage/#/oneid" }, // trial = 测试环境
            develop: { MR: "http://dev.geemro.com/pms-manage/#/oneid" }, // develop = 开发环境
            local: { MR: "https://dev.geemro.com/pms-manage/#/oneid" },
          },
          color: "#e98343",
          bgColor: "linear-gradient(241deg, #E07A41 0%, #CE733E 100%)",
        },
        {
          id: "unit-crm",
          title: "会员CRM",
          note: "个人会员和企业会员的精细化管理平台，包含会员营销功能，会员客户可使用预订小程序订房。",
          url: {
            release: {
              MR: "https://pms.geemro.com/unit-crm/#/oneid",
              BY: "https://pms.geemro.cn/unit-crm/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/unit-crm/#/oneid" },
            develop: { MR: "https://dev.geemro.com/unit-crm/#/oneid" },
            local: { MR: "https://dev.geemro.com/unit-crm/#/oneid" },
          },
          color: "#8D5EFD",
          bgColor: "linear-gradient(241deg, #8149F7 0%, #8540F8 100%)",
        },
        {
          id: "pms-activity",
          title: "营销中心",
          note: "集团营销策略的集成化管理平台，在线管理会员客户的卡券权益，精准设定线上营销活动的投放策略。",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms-activity/#/oneid",
              BY: "https://pms.geemro.cn/pms-activity/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/pms-activity/#/oneid" },
            develop: { MR: "https://dev.geemro.com/pms-activity/#/oneid" },
            local: { MR: "https://dev.geemro.com/pms-activity/#/oneid" },
          },
          color: "#dc744e",
          bgColor: "linear-gradient(241deg, #DA824F 0%, #E4494C 100%)",
        },
        {
          id: "cms",
          title: "CMS集团管理后台",
          note: "集团统一管控各子系统组织架构、运营规则的设置后台。",
          url: {
            release: {
              MR: "https://pms.geemro.com/cms/#/oneid",
              BY: "https://pms.geemro.cn/cms/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/cms/#/oneid" },
            develop: { MR: "https://dev.geemro.com/cms/#/oneid" },
            local: { MR: "https://dev.geemro.com/cms/#/oneid" },
          },
          color: "#4ba691",
          bgColor: "linear-gradient(241deg, #439C86 0%, #3A8A7D 100%)",
        },
        {
          id: "hotel",
          title: "酒店信息管理系统",
          note: "官方预订渠道的酒店信息发布平台，酒店与房型的基础信息、图片将在预订平台中向客户展示。",
          url: {
            release: {
              MR: "https://pms.geemro.com/hotel/#/oneid",
              BY: "https://pms.geemro.cn/hotel/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/hotel/#/oneid" },
            develop: { MR: "https://dev.geemro.com/hotel/#/oneid" },
            local: { MR: "https://dev.geemro.com/hotel/#/oneid" },
          },
          color: "#209cff",
          bgColor: "linear-gradient(241deg, #2E8AFC 0%, #2E8AFC 100%)",
        },
        {
          id: "invoice",
          title: "智慧发票",
          note: "门店专普票解决方案，直连云PMS与税务系统，客人离店自助一秒开票，多渠道且零出错的轻松运营工具。",
          url: {
            release: {
              MR: "https://pms.geemro.com/invoice/#/oneid",
              BY: "https://pms.geemro.cn/invoice/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/invoice/#/oneid" },
            develop: { MR: "https://dev.geemro.com/invoice/#/oneid" },
            local: { MR: "https://dev.geemro.com/invoice/#/oneid" },
          },
          color: "#A1837B",
          bgColor: "linear-gradient(241deg, #A88A7F 0%, #967870 100%)",
        },
        {
          id: "qcs",
          title: "门店质检",
          note: "质检、整改、复查全流程闭环的酒店巡检应用，实时跟进质检结果与整改状态，打造高品质酒店。",
          url: {
            release: {
              MR: "https://work.geemro.com/qcs/#/oneid",
              BY: "https://work.geemro.com/qcs/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/qcs/#/oneid" },
            develop: { MR: "https://dev.geemro.com/qcs/#/oneid" },
            local: { MR: "https://dev.geemro.com/qcs/#/oneid" },
          },
          color: "#6465e7",
          bgColor: "linear-gradient(241deg, #7468EF 0%, #5F4BF2 100%)",
        },
        {
          id: "ota",
          title: "OTA直连",
          note: "官方预订渠道产品与OTA渠道产品的直连管理平台，实现酒店产品房价、房态、订单与OTA渠道的实时同步。",
          url: {
            release: {
              MR: "https://pms.geemro.com/ota/#/oneid",
              BY: "https://pms.geemro.cn/ota/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/ota/#/oneid" },
            develop: { MR: "https://dev.geemro.com/ota/#/oneid" },
            local: { MR: "https://dev.geemro.com/ota/#/oneid" },
          },
          color: "#6465e7",
          bgColor: "linear-gradient(60deg, #CE3FB6 4%, #FF9071 99%)",
        },
        {
          id: "ics",
          title: "库存与成本管理",
          note: "覆盖物资出入库、盘点盘账、成本管控等各方面业务流程，为酒店和餐饮实现业财一体化的高效管理平台。",
          url: {
            release: {
              MR: "https://pms.geemro.com/ics/#/oneid",
              BY: "https://pms.geemro.cn/ics/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/ics/#/oneid" },
            develop: { MR: "https://dev.geemro.com/ics/#/oneid" },
            local: { MR: "https://dev.geemro.com/ics/#/oneid" },
          },
          color: "#34B58C",
          bgColor: "linear-gradient(241deg, #7DAED1 2%, #34B58C 100%)",
        },
        {
          id: "union",
          title: "酒店联盟",
          note: "杰姆龙酒店联盟合作伙伴后台，可设置联盟酒店互订规则，维护合作集团结算信息和确认对账单。",
          url: {
            release: {
              MR: "https://pms.geemro.com/union/#/oneid",
              BY: "https://pms.geemro.cn/union/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/union/#/oneid" },
            develop: { MR: "https://dev.geemro.com/union/#/oneid" },
            local: { MR: "https://dev.geemro.com/union/#/oneid" },
          },
          color: "#e08e56",
          bgColor: "linear-gradient(241deg, #DA824F 0%, #E4494C 100%)",
        },
      ],
      dialogName: null,
    };
  },

  mounted() {
    // 备用线路只显示PMS一个入口
    if (localStorage.HOST_CODE == "BY") {
      this.index.splice(3, this.index.length - 1);
    }
    const { passwordModifyTime } = JSON.parse(localStorage.getItem("user"));
    const now = new Date().getTime(); // 当前时间
    const past = new Date(passwordModifyTime).getTime(); // 密码设置时间
    if (now - past > 86400e3 * 91) {
      this.dialogName = "password"; // 超过 91 天未更改密码, 则弹出改密弹框
    }
  },
};
</script>

<style scoped>
@import "../../styles/dashboard-bg.scss";
.content {
  margin: 0 auto;
  width: 1200px;
}
.title {
  padding: 24px 16px;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
